import { format } from 'date-fns';

import { DataPoint } from 'types/chart';
import { BatchRange } from 'types/reports';

export type Operation = (...values: number[]) => number;

export function calculateYValue(
  dataPoints: DataPoint[],
  comparisonData: DataPoint[] | undefined,
  operation: Operation,
) {
  if (dataPoints.length === 0) return 0;

  let yArray = dataPoints.flatMap((x) => Number.parseFloat(x.yValue));

  if (comparisonData) {
    yArray = [...yArray, ...comparisonData.flatMap((x) => Number.parseFloat(x.yValue))];
  }

  const yValue = operation(...yArray);
  const exponential = Math.floor(Math.log10(Math.abs(yValue)));
  const calcAmount = Math.pow(10, exponential);

  const isOperationMin = operation === Math.min;
  let result = Math.round(yValue / calcAmount) * calcAmount || 0;
  result = isOperationMin ? result - calcAmount : result + calcAmount;
  return result > 0 && isOperationMin ? 0 : result;
}

export function getDefaultData(selectionStart: number, selectionEnd: number, range: string, isMoney: boolean) {
  const startDate = new Date();
  const endDate = new Date();

  const rangeOffsets: { [key: string]: number } = {
    '7days': 7,
    '14days': 14,
    '30days': 30,
    [BatchRange.Last7Days]: 7,
    [BatchRange.Last14Days]: 14,
    [BatchRange.Last30Days]: 30,
    [BatchRange.Last60Days]: 60,
  };

  if (range === BatchRange.Custom) {
    startDate.setDate(startDate.getDate() - selectionStart);
    endDate.setDate(endDate.getDate() - selectionEnd);
  } else {
    const daysOffset = rangeOffsets[range];
    if (daysOffset !== undefined) {
      startDate.setDate(startDate.getDate() - daysOffset);
      endDate.setDate(endDate.getDate() - 1);
    }
  }

  const output: DataPoint[] = [];

  const dateIter = new Date(startDate);
  while (dateIter.getTime() <= endDate.getTime()) {
    output.push({
      xValue: dateIter.toISOString(),
      yValue: '0',
      xValueFormatted: format(dateIter, 'MM/dd'),
      yValueFormatted: isMoney ? '$0.00' : '0',
    });
    dateIter.setDate(dateIter.getDate() + 1);
  }

  return output;
}
