import { VegaBox, VegaInputSelect } from '@heartlandone/vega-react';
import { useTranslation } from 'next-i18next';

import { ChartDateRange, CustomOptions } from 'types/chart';

import styles from './comparisonPicker.module.css';

interface Props {
  selection: ChartDateRange;
  onSelect: (selection: ChartDateRange) => void;
}

const ComparisonPicker: React.FC<Props> = ({ onSelect, selection }) => {
  const { t } = useTranslation('reports');

  const handleSelection = (value: any) => {
    onSelect({ ...selection, comparison: value == 'none' ? null : value });
  };

  const customOptionsForVega = CustomOptions.map((x) => ({ displayName: t(x.label), id: x.value }));

  return (
    <VegaBox color="v-text-primary" className={styles.row}>
      <VegaInputSelect
        size={'small'}
        label={t('compareTo')}
        value={selection.comparison == null ? 'none' : selection.comparison}
        data-testid="transaction-chart-comparsion-selector"
        style={{ width: '275px' }}
        onVegaChange={(e) => {
          const targetValue = e.target.value;
          handleSelection(targetValue.toString());
        }}
        className={styles.select}
        source={customOptionsForVega}
      />
    </VegaBox>
  );
};

export default ComparisonPicker;
