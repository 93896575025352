import React, { useMemo, useState } from 'react';

import { DatePickerModeType, DatePickerValueType } from '@heartlandone/vega';
import { VegaDatePicker, VegaFieldError } from '@heartlandone/vega-react';
import { DateRange } from '@mui/x-date-pickers-pro';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import isValid from 'date-fns/isValid';
import set from 'date-fns/set';
import sub from 'date-fns/sub';
import { useTranslation } from 'next-i18next';

import { ChartDateRange, Comparison } from 'types/chart';
import { DefaultDateFormat } from 'types/reports';
import { dateOutOfRange, formattedMaxReportDate, maxReportDate, minReportDate } from 'utility/helper/helperFunctions';

interface Props {
  selection: ChartDateRange;
  onClick: (selection: ChartDateRange) => void;
}

const CustomRangePicker: React.FC<Props> = ({ selection, onClick }) => {
  const { t } = useTranslation('reports');

  const rangePlaceHolderLabels = [t('dateRange.startDate'), t('dateRange.endDate')];

  const [value, setValue] = useState<DateRange<Date>>(() =>
    selection.range === Comparison.CUSTOM
      ? [sub(new Date(), { days: selection.start }), sub(new Date(), { days: selection.end })]
      : [null, null],
  );

  const [error, setError] = useState<boolean>(false);
  const invalidDateMessage = useMemo(() => {
    return `${t('errorMessages.invalidRange')} ${format(minReportDate(), DefaultDateFormat)} ${t(
      'errorMessages.and',
    )} ${format(maxReportDate(), DefaultDateFormat)} ${t('errorMessages.inclusive')}`;
  }, [t]);

  const applyDate = (date1: Date | null, date2: Date | null) => {
    setError(false);
    if (date1 != null && date2 != null) {
      if (!isValid(date1) || !isValid(date2)) {
        setError(true);
        return;
      }

      const startDate = set(date1, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      const endDate = set(date2, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

      if (
        dateOutOfRange(maxReportDate(), minReportDate(), startDate) ||
        dateOutOfRange(maxReportDate(), minReportDate(), endDate)
      ) {
        setError(true);
        return;
      }
      if (startDate > endDate || endDate < startDate) {
        setError(true);
        return;
      }

      const today = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });

      const res: ChartDateRange = {
        range: Comparison.CUSTOM,
        start: differenceInDays(today, startDate),
        end: differenceInDays(today, endDate),
        compareEnd: undefined,
        compareStart: undefined,
      };

      setValue([date1, date2]);

      onClick(res);
    } else setError(true);
  };

  return (
    <VegaFieldError message={error ? invalidDateMessage : undefined}>
      <VegaDatePicker
        mode="range"
        data-testid="transaction-chart-report-date-selector"
        size="small"
        label={t('customDate')}
        value={value as unknown as DatePickerValueType<DatePickerModeType> | undefined}
        placeholder={rangePlaceHolderLabels}
        maxDate={formattedMaxReportDate()}
        onVegaChange={(e: any) => {
          const [start, end] = e.target.value;

          if (start === null || end === null) {
            return;
          }

          const dateStart = new Date(start);
          const dateEnd = new Date(end);

          if (isEqual(dateStart, value[0] ?? new Date()) && isEqual(dateEnd, value[1] ?? new Date())) {
            return;
          }

          if (start !== '' && end !== '') {
            applyDate(dateStart, dateEnd);
          }
        }}
        clearButton={true}
      />
    </VegaFieldError>
  );
};

export default CustomRangePicker;
