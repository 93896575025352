export enum DashboardComponent {
  TransactionChart = 'TransactionChart',
  GrossSales = 'GrossSales',
  PaymentTypeChart = 'PaymentTypeChart',
  DiscoverProducts = 'DiscoverProducts',
  WelcomeCard = 'WelcomeCard',
  LastDeposit = 'LastDeposit',
  SalesRepresentativeCard = 'SalesRepresentativeCard',
  HelpCenterCard = 'HelpCenterCard',
}

export type CustomDashboard = {
  components: CustomDashboardComponent[];
  maxRows: number;
  maxColumns: number;
};

export type CustomDashboardComponent = {
  name: DashboardComponent;
  height: number;
  width: number;
  column: number;
  row: number;
  configurationJSON: string;
};

export interface DashboardComponentProps {
  initialConfigurationJSON: string;
  saveCustomization: (componentName: DashboardComponent, configurationJSON: string) => void;
}

export const MaxRows = 5;
export const MaxColumns = 4;

export enum UnverifiedUserComponent {
  Welcome = 'WelcomeMessage',
  Verify = 'VerifyAccount',
  GetStarted = 'GetStarted',
}

export interface UnverifiedUserModalContextType {
  isOpen: boolean;
  currentModalContent: UnverifiedUserComponent;
  showModalFlag: boolean;
  openModal: (component: UnverifiedUserComponent) => void;
  closeModal: () => void;
  setModalContent: (content: UnverifiedUserComponent) => void;
}

export type CustomizeDashboardParam = {
  componentName: string;
  configurationJSON: string;
};
