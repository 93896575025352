import { ChartData, ChartTypes } from 'types/chart';
import {
  BatchDetails,
  BatchRange,
  batchRangeValue,
  BatchSummary,
  GrossSalesResult,
  LastDepositResult,
  OpenBatchSummary,
  PaymentTypeDataResult,
  SalesRepresentative,
  StatementRange,
  statementRangeValue,
  Terminal,
} from 'types/reports';
import { Statement } from 'types/reports/statement';

import apiFunctions from '../..';

const ReportsBaseUrl = '/api/v1/Report/';
export const ReportsUrls = {
  getChartByName: ReportsBaseUrl + 'GetChartByName',
  getMerchantBatchSummary: ReportsBaseUrl + 'GetMerchantBatchSummary',
  getTerminalNumbers: ReportsBaseUrl + 'GetTerminalNumbers',
  getClosedBatchDetails: ReportsBaseUrl + 'GetClosedBatchDetails',
  getLastDeposit: ReportsBaseUrl + 'GetLastDeposit',
  getGrossSales: ReportsBaseUrl + 'GetGrossSales',
  getOpenBatchSummary: ReportsBaseUrl + 'GetOpenBatchSummary',
  getPaymentTypeChartData: ReportsBaseUrl + 'GetPaymentTypeChartData',
  getSalesRepresentative: ReportsBaseUrl + 'GetSalesRepresentative',
  getStatementReportData: ReportsBaseUrl + 'GetStatementReportData',
};

async function GetChartByName(type: ChartTypes, minDate: string, maxDate: string): Promise<ChartData> {
  return await apiFunctions.post(ReportsUrls.getChartByName, {
    chartName: type,
    minDate,
    maxDate,
  });
}

async function GetMerchantBatchSummary(
  batchNumberType: 'Open' | null,
  range: BatchRange,
  filterText?: string | null,
  minDate?: string | null,
  maxDate?: string | null,
  terminalId?: Array<string> | null,
): Promise<BatchSummary[]> {
  return await apiFunctions.post(ReportsUrls.getMerchantBatchSummary, {
    minDate,
    maxDate,
    batchNumber: batchNumberType,
    terminalIds: terminalId ?? [],
    terminalDescription: filterText ?? '',
    range: batchRangeValue[range],
    closedBatchesOnly: batchNumberType ? null : true,
  });
}

async function GetTerminalNumbers(
  range: BatchRange,
  minDate?: string | null,
  maxDate?: string | null,
): Promise<Terminal[]> {
  return await apiFunctions.post(ReportsUrls.getTerminalNumbers, { range: batchRangeValue[range], minDate, maxDate });
}

async function GetClosedBatchDetails(
  batchNumber: string | null,
  batchId: string | null,
  transactionDate: string | null,
): Promise<BatchDetails[]> {
  return await apiFunctions.post(ReportsUrls.getClosedBatchDetails, {
    batchNumber,
    batchId,
    transactionDate,
  });
}

async function GetLastDeposit(): Promise<LastDepositResult> {
  return await apiFunctions.post(ReportsUrls.getLastDeposit, {});
}

async function GetGrossSales(range: BatchRange, minDate?: string, maxDate?: string): Promise<GrossSalesResult> {
  return await apiFunctions.post(ReportsUrls.getGrossSales, {
    range: batchRangeValue[range],
    customMinDate: minDate,
    customMaxDate: maxDate,
  });
}

async function GetOpenBatchSummary(): Promise<OpenBatchSummary> {
  return await apiFunctions.post(ReportsUrls.getOpenBatchSummary, {});
}

async function GetPaymentTypeChartData(
  range: BatchRange,
  minDate?: string,
  maxDate?: string,
): Promise<PaymentTypeDataResult[]> {
  return await apiFunctions.post(ReportsUrls.getPaymentTypeChartData, {
    range: batchRangeValue[range],
    customMinDate: minDate,
    customMaxDate: maxDate,
  });
}

async function GetSalesRepresentative(): Promise<SalesRepresentative> {
  return await apiFunctions.post(ReportsUrls.getSalesRepresentative, {});
}

async function GetStatementReportData(range: StatementRange, minDate?: string, maxDate?: string): Promise<Statement[]> {
  return await apiFunctions.post(ReportsUrls.getStatementReportData, {
    range: statementRangeValue[range],
    customMinDate: minDate,
    customMaxDate: maxDate,
  });
}

const reportService = {
  GetChartByName,
  GetMerchantBatchSummary,
  GetTerminalNumbers,
  GetClosedBatchDetails,
  GetLastDeposit,
  GetGrossSales,
  GetOpenBatchSummary,
  GetPaymentTypeChartData,
  GetSalesRepresentative,
  GetStatementReportData,
};

export default reportService;
