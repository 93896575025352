import { FC, useContext, useState } from 'react';

import { VegaButtonLink, VegaCard, VegaFlex, VegaFont, VegaGrid } from '@heartlandone/vega-react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Chart from 'components/charts/chart';
import { UserContext } from 'components/user/auth/userContext';
import useChartData, { useComparisonChartData } from 'hooks/reports/useChartData';
import { ChartDateRange, ChartTypes, Comparison } from 'types/chart';
import { DashboardComponent, DashboardComponentProps } from 'types/dashboard';
import { routes } from 'types/pages';

import ChartRangeSelection from './chartRangeSelection';
import {
  colorAccentPrimaryFill,
  colorAccentPrimaryStroke,
  colorAccentSecondaryFill,
  colorAccentSecondaryStroke,
} from './constants';

import styles from './index.module.css';

const TransactionChart: FC<DashboardComponentProps> = ({ initialConfigurationJSON, saveCustomization }) => {
  const router = useRouter();
  const user = useContext(UserContext);
  const [selection, setSelection] = useState<ChartDateRange>(JSON.parse(initialConfigurationJSON));
  const { t } = useTranslation('reports');

  const handleSelectionChange = (newSelection: ChartDateRange, clearComparison: boolean = false) => {
    saveCustomization(DashboardComponent.TransactionChart, JSON.stringify(newSelection));
    setSelection((prev) => {
      clearComparison = newSelection.comparison === null;
      if (clearComparison) return newSelection;
      // If a comparison still exists and previous and incoming is not custom, preserve it.
      if (prev.comparison && prev.comparison !== Comparison.CUSTOM && newSelection.comparison !== Comparison.CUSTOM) {
        return {
          ...newSelection,
          comparison: newSelection.comparison ?? prev.comparison,
        };
      }
      return newSelection;
    });
  };

  const {
    data: revenueData,
    isLoading: isRevenueLoading,
    isError: isRevenueError,
  } = useChartData(ChartTypes.REVENUE_BY_DAY, selection);
  const {
    data: transactionsData,
    isLoading: isTransactionsLoading,
    isError: isTransactionsError,
  } = useChartData(ChartTypes.TRANSACTION_COUNT_BY_DAY, selection);

  const revenueComparison = useComparisonChartData(ChartTypes.REVENUE_BY_DAY, selection, user.activeCustomer!);
  const transactionsComparison = useComparisonChartData(
    ChartTypes.TRANSACTION_COUNT_BY_DAY,
    selection,
    user.activeCustomer!,
  );

  return (
    <VegaCard padding={'size-24'} className={styles.chartContainer}>
      <VegaFlex
        direction={{ S: 'col', M: 'row', L: 'row' }}
        gap="size-8"
        alignItems={{ S: 'start', M: 'center', L: 'center' }}
      >
        <VegaFont variant={'font-h5'} style={{ flex: 1 }}>
          {user.activeCustomerInformation?.dbaName ?? 'Your Business'} {t('glance')}
        </VegaFont>
        <VegaButtonLink
          label={t('viewReports')}
          iconAlign="right"
          icon="fa fa-chevron-right"
          data-testid="link-view-reports"
          onVegaClick={() => {
            router.push(routes.batchesAndTransactions);
          }}
          size="small"
        />
      </VegaFlex>
      <ChartRangeSelection selection={selection} setSelection={handleSelectionChange} />
      <VegaGrid
        style={{ width: '100%', paddingTop: '30px', paddingBottom: '35px' }}
        column={{ S: 1, M: selection.range === Comparison.CUSTOM ? 1 : 2 }}
        gap={{ S: 'size-64', M: selection.range === Comparison.CUSTOM ? 'size-64' : 'size-16' }}
      >
        <Chart
          selection={selection}
          stroke={colorAccentPrimaryStroke}
          fill={colorAccentPrimaryFill}
          yValueIsCurrency
          data={revenueData}
          isLoading={isRevenueLoading}
          isError={isRevenueError}
          comparison={revenueComparison}
        />
        <Chart
          selection={selection}
          stroke={colorAccentSecondaryStroke}
          fill={colorAccentSecondaryFill}
          data={transactionsData}
          isLoading={isTransactionsLoading}
          isError={isTransactionsError}
          comparison={transactionsComparison}
          allowDecimals={false}
        />
      </VegaGrid>
    </VegaCard>
  );
};

export default TransactionChart;
