export enum ChartTypes {
  REVENUE_BY_DAY,
  TRANSACTION_COUNT_BY_DAY,
  FUNDING_AMOUNT_BY_DAY,
}

export type ChartDateRange = {
  range: string;
  start: number;
  end: number;
  expiration?: Date;
  comparison?: Comparison;
  compareStart?: number;
  compareEnd?: number;
};

export enum Comparison {
  LAST7_DAYS = 'LAST7_DAYS',
  LAST_YEAR = 'LAST_YEAR',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM = 'CUSTOM',
}

export type DataPoint = {
  xValue: string;
  yValue: string;
  xValueFormatted: string;
  yValueFormatted: string;
};

export type NamedChartData = {
  chartLabel: { xLabel: string; yLabel: string };
  dataPoints: Array<DataPoint>;
  title: string;
  units: string;
};

export type ChartData = NamedChartData;

export const CustomOptions = [
  { value: 'none', label: 'none' },
  { value: Comparison.LAST_MONTH, label: 'sameMonth' },
  { value: Comparison.LAST_YEAR, label: 'sameYear' },
];

export const DateOptions: Array<ChartDateRange> = [
  // {
  //   range: 'Yesterday',
  //   subtract: 1,
  // },
  {
    range: '7days',
    start: 7,
    end: 1,
  },
  {
    range: '14days',
    start: 14,
    end: 1,
  },
  {
    range: '30days',
    start: 30,
    end: 1,
  },
];
